/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  //Layout states
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [showPopup, setShowPopup] = useState("");
  const [dataDatte, setDataDatte] = useState([]);
  const [idProduct, setIdProduct] = useState("");

  //Login status
  const [loginStatus, setLoginStatus] = useState(false);
  const rememberMe = useRef();
  const [userType, setUserType] = useState([]);

  //User
  const [localUserData, setLocalUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [userPerm, setUserPerm] = useState(
    JSON.parse(localStorage?.getItem("userType"))
  );

  const [chooseVehicule, setChooseVehicule] = useState();

  //Application data
  const [dashboardData, setDashboardData] = useState();
  const [depotsData, setDepotsData] = useState({});
  const [magasinsData, setMagasinsData] = useState({});

  const [magasinData, setmagasinData] = useState([]);
  const [depotData, setdepotData] = useState([]);

  const [nomClient, setnomClient] = useState("");
  const currentCustom = useRef("");
  const [tauxData, setTauxData] = useState({});
  const [categorieProd, setCategorieProd] = useState({});
  const [produits, setProduits] = useState({});
  const [produitsVente, setProduitsVente] = useState({});
  const [stocks, setStocks] = useState({});
  const [approvData, setApprovData] = useState({});
  const [receptionData, setReceptionData] = useState({});
  const [venteData, setVenteData] = useState({});
  const [transfertData, setTransfertData] = useState({});
  const [receptions, setReceptions] = useState({});
  const [fournissData, setFournissData] = useState({});
  const [clientData, setClientData] = useState({});
  const [clientspecialData, setClientspecialData] = useState({});
  const [agentsData, setAgentsData] = useState({});
  const [agentAddUser, setAgentAddUser] = useState({});
  const [receptionRegister, setReceptionRegister] = useState({});
  const [vehiculesData, setVehiculesData] = useState({});
  const [vehiculeListData, setVehiculeListData] = useState({});
  const [transporteursData, setTransporteursData] = useState({});
  const [transporterDataList, setTransporterDataList] = useState({});
  const [transporteurDataList, setTransporteutDataList] = useState([]);
  const [traspAffect, setTraspAffect] = useState({});
  const [usersData, setUsersData] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [usersAccess, setUsersAccess] = useState({});
  const [selectedUserAccess, setSelectedUserAccess] = useState({});
  const [accessData, setAccessData] = useState({});
  const [openConfig, setopenConfig] = useState(false);
  const [dataDepot, setdataDepot] = useState({});
  const [depot, setDepot] = useState([]);
  const [specialData, setSpecialData] = useState([]);
  //Local app data to upload
  const [panierApprov, setPanierApprov] = useState([]);
  const updatePanierApprov = [...panierApprov];
  const [panierVente, setPanierVente] = useState([]);
  const [panierProforma, setPanierProforma] = useState([]);
  const updatePanierVente = [...panierVente];
  const updatePanierProforma = [...panierProforma];
  const [panierTransfert, setPanierTransfert] = useState([]);
  const updatePanierTransf = [...panierTransfert];
  const [fetchDestTransf, setFetchDestTransf] = useState("magasin");
  const [addData, setAddData] = useState({
    addApprov: {
      designation: "",
      nomFournisseur: "",
      qte: 1,
      pa: 0,
    },
    addVente: {
      designation: "",
      qte: 1,
      pu: 0,
      qteMagasin: 0,
      qteDepot: 0,
      pugros: 0,
      pudetail: 0,
    },
    addTransf: {
      id: "",
      designation: "",
      qte: 1,
      qteMagasin: 0,
      qteDepot: 0,
      pugros: 0,
      pudetail: 0,
    },
  });

  //fetch Status
  const [canFetch, setCanFetch] = useState({
    histTransfert: true,
    loadChauffeur: true,
    special: true,
    dash: true,
    depot: true,
    mag: true,
    taux: true,
    categProd: true,
    prods: true,
    prodsVente: true,
    solde: true,
    stock: true,
    approv: true,
    vente: true,
    frss: true,
    agents: true,
    client: true,
    clientspecial: true,
    approvData: true,
    receptData: true,
    venteData: true,
    transfData: true,
    recept: true,
    receiveOne: true,
    vehicules: true,
    vehiculeList: true,
    transporter: true,
    transporterList: true,
    users: true,
    userAccess: true,
    access: true,
    loadingDepot: true,
    vehiculeProv: true,
    vehiculesTransporter: true,
  });
  const [inLoading, setInLoading] = useState({
    histTransfert: false,
    vehiculeProv: false,
    dash: false,
    depot: false,
    mag: false,
    taux: false,
    categProd: false,
    prods: false,
    prodsVente: false,
    stock: false,
    approv: false,
    vente: false,
    frss: false,
    agents: false,
    client: false,
    clientspecial: false,
    special: false,
    approvData: false,
    receptData: false,
    venteData: false,
    transfData: false,
    recept: false,
    receiveOne: false,
    vehicules: false,
    vehiculeList: false,
    transporter: false,
    transporterList: false,
    users: false,
    userAccess: false,
    access: false,
    loadingDepot: false,
    loadChauffeur: false,
    vehiculesTransporter: true,
  });

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
    setThemeSettings(false);
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };

  return (
    <StateContext.Provider
      value={useMemo(() => ({
        //Layout states
        loginStatus,
        setLoginStatus,
        rememberMe,
        showPopup,
        setShowPopup,
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,
        currentMode,
        currentColor,
        themeSettings,
        setThemeSettings,
        setColor,
        setMode,
        nomClient,
        setnomClient,
        currentCustom,
        //User
        localUserData,
        setLocalUserData,
        token,
        setToken,
        userType,
        setUserType,
        userPerm,
        setUserPerm,
        openConfig,
        setopenConfig,
        //Application data
        setDepot,
        depot,
        dashboardData,
        setDashboardData,
        depotsData,
        setDepotsData,
        specialData,
        setSpecialData,
        magasinsData,
        setMagasinsData,
        tauxData,
        setTauxData,
        categorieProd,
        setCategorieProd,
        produits,
        setProduits,
        produitsVente,
        setProduitsVente,
        stocks,
        setStocks,
        approvData,
        setApprovData,
        receptionData,
        setReceptionData,
        venteData,
        setVenteData,
        transfertData,
        setTransfertData,
        receptions,
        setReceptions,
        fournissData,
        setFournissData,
        clientData,
        setClientData,
        clientspecialData,
        setClientspecialData,
        agentsData,
        setAgentsData,
        agentAddUser,
        setAgentAddUser,
        receptionRegister,
        setReceptionRegister,
        vehiculesData,
        setVehiculesData,
        vehiculeListData,
        setVehiculeListData,
        transporteursData,
        setTransporteursData,
        transporterDataList,
        setTransporterDataList,
        traspAffect,
        setTraspAffect,
        usersData,
        setUsersData,
        selectedUser,
        setSelectedUser,
        usersAccess,
        setUsersAccess,
        selectedUserAccess,
        setSelectedUserAccess,
        accessData,
        setAccessData,
        dataDepot,
        setdataDepot,
        specialData,
        setSpecialData,
        //Loading Depot
        setDepot,
        depot,
        chooseVehicule,
        setChooseVehicule,
        //Local app data to upload
        panierApprov,
        setPanierApprov,
        updatePanierApprov,
        panierVente,
        setPanierVente,
        panierProforma,
        setPanierProforma,
        updatePanierProforma,
        updatePanierVente,
        panierTransfert,
        setPanierTransfert,
        updatePanierTransf,
        addData,
        setAddData,
        fetchDestTransf,
        setFetchDestTransf,
        transporteurDataList,
        setTransporteutDataList,
        //fetch Status
        canFetch,
        setCanFetch,
        inLoading,
        setInLoading,
        dataDatte,
        setDataDatte,
        depotData,
        setdepotData,
        magasinData,
        setmagasinData,
        idProduct,
        setIdProduct,
      }))}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
